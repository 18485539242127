import { BaseModel, DRFModelError } from "../../../legl-ui/utils/base-model.js";
import { post, put } from "../../../legl-ui/utils/fetch.js";
import { snakeToCamelCase } from "../../../legl-ui/utils/functions";

export class Contact extends BaseModel {
  static get endpoint() {
    return "/v2/entity/";
  }

  static get schema() {
    return {
      required: ["first_name", "last_name"],
      type: "object",
      properties: {
        uid: {
          type: "integer",
        },
        url: {
          type: "string",
          format: "url",
        },
        client_reference: {
          type: "string",
        },
        first_name: {
          type: "string",
        },
        middle_name: {
          type: "string",
        },
        last_name: {
          type: "string",
        },
        name: {
          type: "string",
        },
        phone_number: {
          type: "string",
        },
        email: {
          type: "string",
          format: "email",
        },
        entity_id: {
          type: "string",
        },
        address: {
          type: "object",
          properties: {
            line_1: {
              type: "string",
            },
            line_2: {
              type: "string",
            },
            region: {
              type: "string",
            },
            country: {
              type: "string",
            },
            postcode: {
              type: "string",
            },
          },
        },
        linked_products: {
          type: "array",
          items: {
            type: "object",
            properties: {
              created_at: {
                type: "string",
                format: "datetime",
              },
              product_type: {
                type: "string",
                enum: ["pay", "engage"],
              },
              product_id: {
                type: "integer",
              },
              url: {
                type: "string",
                format: "url",
              },
            },
          },
        },
        businesses: {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              type: {
                type: "string",
              },
              role: {
                type: "string",
              },
              shareholder_percentage: {
                type: "string",
              },
            },
          },
        },
        history: {
          type: "string",
          format: "url",
        },
        latest_cdd_step: {
          type: "object",
        },
        total_amount_paid: {
          type: "number",
        },
        type: {
          type: "string",
        },
        date_of_birth: {
          type: "string",
          format: "datetime",
        },
        created_at: {
          type: "string",
          format: "datetime",
        },
      },
    };
  }
  async save() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.validate();
    let res;
    const bodyObject = Object.entries(this._data).reduce(
      (dataOut, [key, value]) => {
        if (Boolean(value) || value === null) {
          dataOut[key] = value;
        }
        return dataOut;
      },
      {},
    );

    let body = JSON.stringify(bodyObject);

    if (this.uid && this.url) {
      res = await put(this.url, {
        body,
      });
    } else {
      body = JSON.stringify({ individual: bodyObject });
      res = await post(this.endpoint, {
        body,
      });
    }

    if (!res.ok) {
      let text = await res.text();
      let messageFromJSONDetail = false;
      try {
        const json = JSON.parse(text);
        if (json.detail) {
          text = json.detail;
          messageFromJSONDetail = true;
        } else if (Object.keys(json).length) {
          text = Object.entries(json)
            .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
            .join(", ");
          messageFromJSONDetail = true;
        }
      } finally {
        this.isSaving = false;
        throw new DRFModelError(text, res.status, messageFromJSONDetail);
      }
    } else {
      const json = await res.json();

      // Iterate through response values and set via setters in case the setter logic has been overridden
      Object.entries(json.individual).forEach(([key, value]) => {
        this[snakeToCamelCase(key)] = value;
      });
      this.entityId = json.id;
      this._data.entity_id = json.id;
    }
    if (this._data.middle_name) {
      this._data["name"] =
        `${this._data.first_name} ${this._data.middle_name} ${this._data.last_name}`;
    } else {
      this._data["name"] = `${this._data.first_name} ${this._data.last_name}`;
    }
    this.isSaving = false;
    return this._data;
  }
}
